<template>
  <div class="snackbar" :style="{ backgroundColor:backgroundColor }">
    <online></online>
    <span class="item">{{message}}</span>
  </div>
</template>

<script>
export default {
  props: ["message", "backgroundColor"],
  created() {
    console.log("entered snackbar");
  },
  components: {
    online: () => import("@/components/SvgImages/Online.vue")
  }
};
</script>


<style lang="scss">
@import "@/sass/_variables.scss";
.snackbar {
  width: 100%;
  position: fixed;
  z-index: 9999;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding: 10px;
  top: 0px;
  font-size: 15px;
  font-weight: bold;
  font-family: $font-regular;
  //   -webkit-animation: fadein 0.5s, fadeout 0.5s 2.8s;
  //   animation: fadein 0.5s, fadeout 0.5s 2.8s;
  .item {
    margin-left: 7px;
  }
}
@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
@-webkit-keyframes fadeout {
  from {
    top: 0px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
@keyframes fadeout {
  from {
    top: 0px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
</style>
